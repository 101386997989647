import axios from "axios"
import { accessToken, getCourseVariant } from "./moocfi"
import CourseSettings from "../../course-settings"

// const id = CourseSettings.quizzesId
const language = CourseSettings.default.language

const quizzesLanguage = language === "en" ? "en_US" : "fi_FI"

export async function fetchQuizzesProgress() {
  return {}
}

export async function fetchQuizNames() {
  return {}
}
