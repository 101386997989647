import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import CourseSettings from "../course-settings"
import commonEN from "./locales/common/en"
import commonDE from "./locales/common/de"
import pointsBalloonEN from "./locales/pointsBalloon/en"
import pointsBalloonDE from "./locales/pointsBalloon/de"
import userEN from "./locales/user/en"
import userDE from "./locales/user/de"
import commonFI from "./locales/common/fi"
import pointsBalloonFI from "./locales/pointsBalloon/fi"
import userFI from "./locales/user/fi"

const resources = {
  de: {
    common: commonDE,
    "points-balloon": pointsBalloonDE,
    user: userDE,
  },
  en: {
    common: commonEN,
    "points-balloon": pointsBalloonEN,
    user: userEN,
  },
  fi: {
    common: commonFI,
    "points-balloon": pointsBalloonFI,
    user: userFI,
  },
}

console.log("Default language:", CourseSettings.default.language) // Add this line

i18n.use(initReactI18next).init({
  resources,
  ns: ["common", "user", "points-balloon"],
  defaultNS: "common",
  react: {
    wait: true,
  },
  lng: CourseSettings.default.language, // Default language
  detection: {
    order: ["querystring", "cookie", "localStorage", "navigator"], // The order in which languages are detected
    caches: ["cookie"], // Cache language preference in cookies
    lookupCookie: "i18next", // Name of the cookie to use for storing language
    lookupLocalStorage: "i18nextLng", // Name of localStorage key
    checkWhitelist: true, // Ensure detected language is in whitelist
  },
  fallbackLng: "de", // Fallback language in case of a missing translation
})
