import * as store from "store"
import fetchPonyfill from "fetch-ponyfill"
import axios from "axios"
import CourseSettings from "../../course-settings"

const { fetch } = fetchPonyfill()
const BASE_URL = "https://google.com"
const ORGANIZATION = CourseSettings.default.tmcOrganization

// This array will hold all the listeners for login state changes
const loginStateListeners = []

// This is the loggedIn function
export function loggedIn() {
  // Checks if there's a stored user with an accessToken
  const user = store.get("tmc.user")
  return user && user.accessToken ? true : false
}

// The onLoginStateChanged function adds a listener for login state changes
export function onLoginStateChanged(callback) {
  loginStateListeners.push(callback)
}

// Call this function whenever the login state changes
function loginStateChanged() {
  const isLoggedIn = loggedIn()
  loginStateListeners.forEach((listener) => listener(isLoggedIn))
}

// Empty implementations for the requested functions
export function resetPassword(email) {
  return new Promise((resolve) => resolve())
}

export function fetchProgrammingProgress(exerciseName) {
  return new Promise((resolve) => resolve())
}

export function fetchProgrammingExerciseModelSolution(exerciseId) {
  return new Promise((resolve) => resolve())
}

export async function fetchProgrammingExerciseDetails(exerciseName) {
  // Return a dummy object or null
  return { available_points: [], awarded_points: [] }
}

export function canDoResearch() {
  try {
    return false
  } catch (error) {
    return false
  }
}
