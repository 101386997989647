const courseSettings = {
  language: "de",
  name: "Prinzipien der Programmierung",
  siteUrl: "https://pdp.algochem.techfak.de/",
    subtitle: "Objektorientierte Programmierung (Java, WiSe 2024/2025)",
  subtitle2: "Daniel Merkle",
  slug: "java-programming",
  tmcCourse: "java-programming",
  quizzesId: "6a635a2d-4e4b-49cc-8487-8d9cab6a74e7",
  tmcOrganization: "techfak",
  bannerPath: "banner.svg",
  sidebarEntries: [
    {
      title: "Über diesen Kurs",
      path: "/",
    },
    {
      title: "Unterstützung und Hilfe",
      path: "/support-and-assistance",
    },
    {
      title: "Übungsaufgaben und Prüfung",
      path: "/grading-and-exams",
    },
    {
      title: "Häufig gestellte Fragen",
      path: "/faq",
    },
    { separator: true, title: "Teile" },
  ],
  sidebarFuturePages: [
  ],
  splitCourses: false,
  useNewPointsVisualization: false
}

module.exports = {
  default: courseSettings,
}
